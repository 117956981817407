@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lateef&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;500;600;700&display=swap);
.collapse {
    display: block;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "تغيير صورة العرض";
}

.custom-file-input:lang(en)~.custom-file-label.editor-choose-image::after {
    content: "تغيير صورة التدوينة";
}

.lineclamp1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.lineclamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 60px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reverse-ellipsis {
    text-overflow: clip;
    position: relative;
    background-color: #FFF;
}

.react-confirm-alert-button-group {
    display: block;
}

.ql-editor .ql-video {
    display: block;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    min-height: 45vw;
    overflow: hidden;
    position: relative;
}

.blogBody {
    unicode-bidi: bidi-override !important;
    direction: unset !important;
    text-align: right;
}

img {
    max-width: 100%;
}

.card-header::after {
    content: none;
}

.btn-danger {
    margin-left: 1rem!important;
}

.md-form .prefix {
    position: relative;
}

.quill {
    position: relative;
    top: 0;
    right: 0;
    font-size: 1rem;
    color: #757575;
    cursor: text;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    transform: translateY(12px);
    transform-origin: 0% 100%;
    margin-right: 2.5rem;
}

.ql-editor {
    min-height: 18em;
}

.ql-editor strong {
    font-weight: bold;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: unset;
}

.card .md-form label {
    width: 100%;
}


/* Set dropdown font-families */

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Cairo"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Cairo"]::before {
    font-family: 'Cairo', sans-serif;
    content: "Cairo";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lateef"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lateef"]::before {
    font-family: "Lateef", cursive;
    content: "Lateef";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="markazi-text"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="markazi-text"]::before {
    font-family: 'Markazi Text', serif;
    content: "Markazi";
}


/* Set content font-families */

.ql-container {
    font-family: "Cairo", sans-serif !important;
    height: 400px !important;
}

.ql-editor.ql-blank::before {
    height: 380px;
    overflow-y: scroll;
}

.ql-font-Cairo {
    font-family: "Cairo", sans-serif;
}

.ql-font-lateef {
    font-family: "Lateef", cursive;
}

.ql-font-markazi-text {
    font-family: "Markazi Text", serif;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 7.5px;
}

.custom-control {
    min-height: 2.5rem;
}

.custom-control-label {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding-right: 1.5rem;
    cursor: pointer !important;
}

.custom-control-label::before {
    left: unset;
    right: 0rem;
}

body {
    font-family: 'Cairo', sans-serif;
}

.react-confirm-alert-body {
    direction: rtl;
    text-align: center;
    font-family: inherit;
}

.md-form.form-group {
    text-align: right;
}

.md-form label {
    right: 0;
    left: unset;
}

.md-form .prefix~label {
    margin-right: 2.5rem;
    margin-left: 0;
}

.custom-control-input {
    right: 0;
    left: unset;
}

.md-form .prefix~input,
.md-form .prefix~textarea {
    margin-right: 2.5rem;
    margin-left: 0;
}

.md-form {
    text-align: right;
}

.loadmore-btn {
    color: black !important;
}
